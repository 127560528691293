<template functional>
  <div
    class="mb-6"
    :class="{ [data.staticClass]: true, [$style['page-title_title']]: true }"
    v-on="listeners"
  >
    <h3 :class="$style['page-title_title']">
      {{ props.title }}
    </h3>

    <h5 :class="$style['page-title-description']">
      {{ props.description }}
    </h5>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss" module>
  .page-title {
    @apply mb-6;
  }
  .page-title_title {
    @apply font-poppins font-bold;
    @apply text-blueGray-800;
    @apply text-2xl;
  }
  .page-title-description {
    @apply text-base font-notoSans font-normal;
    @apply text-blueGray-600;
  }
</style>
