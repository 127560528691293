<template functional>
  <div :class="$style['space-x']" />
</template>

<script>
  export default {
    functional: true
  }
</script>

<style lang="scss" module>
  .space-x {
    @apply flex-grow;
  }
</style>
