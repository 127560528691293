export enum FEATURE_CODE {
  WHITELABEL = 'whitelabel',
  OCTA_LOGO = 'octa-logo',
  API_KEY_MANAGEMENT = 'api-key-management',
  USER_MANAGEMENT = 'user-management',
  ASK_ME = 'ask-me',
  BOTCONDITIONAL = 'botconditional',
  BOTWEBHOOK = 'botwebhook',
  BSP_SOCIALMINER_ONBOARDING = 'bsp-socialminer-onboarding',
  CHATBOT = 'chatbot',
  CHATBOT_EMAIL = 'chat-email',
  EVENT_HANDLER_ENABLED = 'event-handler-enabled',
  COMMERCE_ENABLED = 'commerce-enabled',
  CONTACT_CUSTOM_FIELDS = 'contact_custom_fields',
  CUSTOM_REPORTS = 'custom_reports',
  EMAIL = 'email',
  INBOX_BOT_DISABLED = 'inbox-bot-disabled',
  INSTAGRAM = 'instagram',
  MESSENGER = 'messenger',
  META_ADS_ENABLED = 'meta-ads-enabled',
  MENSAGENS_PRONTAS = 'mensagens_prontas',
  NEW_BILLING = 'new-billing',
  NEW_OCTA_MIGRATED = 'new-octa-migrated',
  NEW_OCTA_NFBOX = 'new-octa-nfbox',
  NEW_OCTA_TICKET = 'new-octa-ticket',
  NEW_WIDGET = 'new-widget',
  OFFICE_HOURS = 'office_hours',
  OPEN_NEW_CHAT = 'open-new-chat',
  REPORTS_EXPORT = 'reports_export',
  RESPONSIBLE_CONTACT_ENABLED = 'responsible-contact-enabled',
  RESPONSIBLE_SERVICE_TIMEOUT = 'response-service-timeout',
  ROULETTE_TICKET = 'roulette-ticket',
  SERVICE_GROUPS = 'service_groups',
  SERVICE_ROULETTE = 'service_roulette',
  SERVICE_SURVEY = 'service_survey',
  SERVICE_TRANSFER = 'service_transfer',
  TEMPLATE_MESSAGE_V2 = 'template-message-v2',
  BULK_MESSAGES_V2 = 'bulk-messages-v2',
  UNASSIGNED_LIST = 'unassigned_list',
  USER = 'user',
  USE_NEW_BOT_BUILDER = 'use-new-bot-builder',
  USE_OLD_BOT_BUILDER = 'use-old-bot-builder',
  WHATSAPP = 'whatsapp',
  WHATSAPP_API = 'whatsapp-api',
  WHATSAPP_API_PKG_MSG_1000 = 'whatsapp-api-pkg-msg-1000',
  WHATSAPP_API_PKG_MSG_250 = 'whatsapp-api-pkg-msg-250',
  WHATSAPP_API_PKG_MSG_500 = 'whatsapp-api-pkg-msg-500',
  WHATSAPP_API_PKG_MSG_OCTA_BSP_SINGLE_250 = 'whatsapp-api-pkg-msg-octa-bsp-single-250',
  WHATSAPP_API_PKG_MSG_OCTA_VIP_SINGLE_250 = 'whatsapp-api-pkg-msg-octa-vip-single-250',
  WHATSAPP_API_PKG_MSG_SINGLE_250 = 'whatsapp-api-pkg-msg-single-250',
  WHATSAPP_BUSINESS_API = 'whatsappBusinessApi',
  WIDGET = 'widget',
  EXTERNAL_USER = 'user_marketplace',
  NEW_CHAT = 'is-new-chat',
  NEW_CHANNELS_NOTIFICATIONS = 'new-channels-notifications',
  PRO_ACTIVE_EMAIL = 'proactive-email',
  CHAT_REMINDER = 'chat-reminder',
  CHAT_AI = 'chat-ai',
  TRAY_ORDER = 'create-tray-order-enabled',
  NUVEM_SHOP_ORDER = 'create-nuvemshop-order-enabled',
  CHAT_AI_DEFAULT_TAB = 'chat-ai-default-tab',
  NEW_OCTA_HOME = 'new-home-feature',
  CREDIT_MANAGEMENT_ENABLED = 'credit-management-enabled',
  COMMERCE_AGENT_CREATE_CART_ENABLED = 'commerce-agent-create-cart-enabled',
  NOTIFICATIONS = 'notifications',
  WOZ_TRANSCRIPTION = 'woz-transcript',
  OCTA_STORE = 'octa-store',
  OCTA_CREDITS_ENABLED = 'octa-credits-enabled',
  GROUPS_INBOX = 'groups-inbox',
  BANNER_IMAGES_ENABLED = 'bannerimages',
  SSO_CONFIG = 'sso-config',
  ROULETTE_TICKET_TOGGLE = 'roulette-ticket-toggle',
  CHAT_NEW_CONVERSATION_V2_ENABLED = 'chat-new-conversation-v2-enabled',
  HOME_USER_CARD = 'home-user-card',
  WOZ_IN_BOT_PREFERRED_USERS = 'woz-in-bot-preferred-users',
  WOZ_IN_BOT = 'woz-in-bot',
  WOZ_SUMMARY_ENABLED = 'woz-summary-enabled',
  CONTENT_GAPS = 'content-gaps',
  RESEND_SURVEY_HELPDESK = 'resend-survey-helpdesk',
  WOZ_BUNDLE = 'woz-bundle',
  DASHBOARD_AGENTS = 'dashboard-agents'
}
