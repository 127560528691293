import { ChatSettings } from '@/common/helpers/interfaces/chat-settings'

import { getClient } from './http'
import legacyHeaders from './legacy-headers'

const API_NAME = 'chatUrl'

export class ChatService {
  getClient(options?: { [key: string]: any }) {
    return getClient(API_NAME, options)
  }

  get() {
    return this.getClient()
      .then(client => client.get('configs', legacyHeaders()))
      .then(response => response.data)
  }

  async save(settings: ChatSettings) {
    try {
      const { data } = await this.getClient().then(client =>
        client.put('configs', settings, legacyHeaders())
      )

      return data
    } catch (ex) {
      if (ex) {
        throw new Error('Error at saveSettings: ' + ex)
      }
    }
  }

  async createGroup(group: any) {
    try {
      const { data } = await this.getClient().then(client =>
        client.post('groups', group, legacyHeaders())
      )

      return data
    } catch (ex) {
      if (ex) {
        throw new Error('Error at saveSettings: ' + ex)
      }
    }
  }

  async getAgents() {
    const { data } = await this.getClient().then(client =>
      client.get('agents', legacyHeaders())
    )

    return data
  }

  async getChatBotsFluxes() {
    const response = await this.getClient().then(client =>
      client.get('flux', legacyHeaders())
    )

    if (response?.data) {
      return response.data.filter((flux: { botId: any }) => flux.botId)
    }

    return []
  }

  async getChatConversationCount(filter: any) {
    try {
      const { data } = await this.getClient({ timeout: 10000 }).then(client =>
        client.post('/rooms/count', filter, legacyHeaders())
      )

      return data
    } catch (ex) {
      if (ex) {
        throw new Error('Error at getChatConversationCount: ' + ex)
      }
    }
  }

  async getAllGroups() {
    const client = await this.getClient()

    const { data } = await client.get('groups', legacyHeaders())

    return data
  }

  async getAllTags() {
    const client = await this.getClient()

    const { data } = await client.get('public-tags', legacyHeaders())

    return data
  }

  async getActiveTags() {
    const client = await this.getClient()

    const { data } = await client.get(
      'public-tags/status/active',
      legacyHeaders()
    )

    return data
  }

  async updateCustomer(customer: any) {
    const client = await this.getClient()

    const { data } = await client.put(
      '/rooms/customer',
      { customer },
      legacyHeaders()
    )

    return data
  }

  async createReadyMessages(payload: {
    comment: string
    creatorId: string
    name: string
    permission: string
  }) {
    try {
      const client = await this.getClient()

      await client.post('/macros', payload, legacyHeaders())
    } catch (ex) {
      throw new Error('Error at createReadyMessages: ' + ex)
    }
  }

  async fetchAgentConversations(filters: any) {
    let result = null
    try {
      const client = await this.getClient()

      const { data } = await client.get(
        `rooms/list-light/${filters.status}?page=${filters.page}&agent._id=${filters.agentId}&sort[property]=${filters.field}&sort[direction]=${filters.order}`,
        legacyHeaders()
      )

      result = data
    } catch (e) {
      throw new Error(`Error ${e}`)
    }
    return result
  }

  async fetchDashboardAgents(withCache: boolean, filters?: any) {
    let result = null
    try {
      const client = await this.getClient()

      const { data } = await client.post(
        'dashboard/agents',
        {
          ...filters,
          withCache
        },
        {
          ...legacyHeaders()
        }
      )

      result = data
    } catch (e) {
      throw new Error(`Error ${e}`)
    }
    return result
  }

  async setAgentStatusBulk(params: any) {
    let result = null
    try {
      const client = await this.getClient()

      const { data } = await client.put('agents/status/bulk', params, {
        ...legacyHeaders()
      })

      result = data
    } catch (e) {
      throw new Error(`Error ${e}`)
    }
    return result
  }
}
