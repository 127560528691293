<template functional>
  <section
    :class="{
      [data.staticClass]: true,
      [$style.page_box]: true,
      [$style['page_box--col']]: !props.row,
      [$style['page_box--row']]: props.row
    }"
    :style="{ maxWidth: `${props.maxWidth}px` }"
  >
    <slot />
  </section>
</template>

<script>
  export default {
    functional: true,
    props: {
      row: Boolean,
      maxWidth: {
        type: [String, Number],
        default: undefined
      }
    }
  }
</script>

<style lang="scss" module>
  .page_box {
    @apply flex mx-auto;
    @apply space-y-6 px-2 md:px-6 pt-6;
  }
  .page_box--col {
    @apply flex-col;
  }
  .page_box--row {
    @apply flex-row;
  }
</style>
