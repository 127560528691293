interface ChatAsideItem {
  component: string
  title?: string
  canClose?: boolean
}

export const ChatAsideEnum: Record<string, ChatAsideItem> = {
  CommerceCatalogs: {
    component: 'CatalogListContainer',
    title: 'chat.conversation.commerce.catalogsList.title'
  },
  CommerceCartDetails: {
    component: 'CartListContainer',
    title: 'chat.conversation.commerce.cartList.title'
  },
  CommerceDetails: {
    component: 'ProductDetailsContainer',
    title: 'chat.conversation.commerce.productDetails.title'
  },
  CommerceProductsSended: {
    component: 'ProductsSendedContainer',
    title: 'chat.conversation.commerce.productsSended.title'
  },
  CommerceSelectedProducts: {
    component: 'SelectedProductsContainer',
    title: 'chat.conversation.commerce.selectedProducts.title'
  },
  CommerceOrdersCreate: {
    component: 'CreateOrderContainer',
    title: 'chat.conversation.commerce.orders.create.title'
  },
  CommerceOrders: {
    component: 'OrdersContainer',
    title: 'chat.conversation.commerce.orders.title'
  },
  CommerceOrdersDetails: {
    component: 'OrderDetailsContainer',
    title: 'chat.conversation.commerce.orders.title'
  },
  CreateCart: {
    component: 'CreateCartContainer',
    title: 'chat.conversation.commerce.createCart.cart.title'
  },
  CreateCartCheckout: {
    component: 'CreateCartCheckoutContainer',
    title: 'chat.conversation.commerce.createCart.checkout.title'
  },
  CreateCartOrder: {
    component: 'CreateCartOrderContainer',
    title: 'chat.conversation.commerce.createCart.order.title'
  },
  WhitelabelMoskit: {
    component: 'Moskit',
    canClose: false
  }
}
