<template>
  <octa-list class="!p-0" role="list" v-bind="$attrs" v-on="$listeners">
    <octa-accordion>
      <slot />
    </octa-accordion>
  </octa-list>
</template>

<script>
  import { OctaAccordion, OctaList } from '@octadesk-tech/web-components'

  export default {
    components: {
      OctaList,
      OctaAccordion
    }
  }
</script>
