export const SET_FETCHED_INITIAL_DATA = 'SET_FETCHED_INITIAL_DATA'

export const SET_CONNECTED = 'SET_CONNECTED'

export const SET_AGENT = 'SET_AGENT'

export const SET_AGENT_STATUS = 'SET_AGENT_STATUS'

export const SET_AGENTS = 'SET_AGENTS'

export const SET_AGENTS_ERROR = 'SET_AGENTS_ERROR'

export const SET_AGENTS_IS_LOADING = 'SET_AGENTS_IS_LOADING'

export const SET_GROUPS = 'SET_GROUPS'

export const SET_GROUPS_ERROR = 'SET_GROUPS_ERROR'

export const SET_GROUPS_IS_LOADING = 'SET_GROUPS_IS_LOADING'

export const SET_RESTRICTED_GROUPS = 'SET_RESTRICTED_GROUPS'

export const SET_ONLINE_AGENTS = 'SET_ONLINE_AGENTS'

export const SET_ALL_CHATS_COUNT = 'SET_ALL_CHATS_COUNT'

export const SET_OWNERS = 'SET_OWNERS'

export const SET_INTEGRATOR_WHATSAPP_NUMBERS = 'SET_INTEGRATOR_WHATSAPP_NUMBERS'

export const SET_WHATSAPP_NUMBERS = 'SET_WHATSAPP_NUMBERS'

export const SET_RESTRICTED_WHATSAPP_NUMBERS = 'SET_RESTRICTED_WHATSAPP_NUMBERS'

export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_SETTINGS_NOTIFY_AGENTS = 'SET_SETTINGS_NOTIFY_AGENTS'

export const SET_INTEGRATORS = 'SET_INTEGRATORS'

export const SET_TRY_CREATE_ROOM = 'SET_TRY_CREATE_ROOM'

export const SET_SELECT_TEMPLATE_MESSAGE = 'SET_SELECT_TEMPLATE_MESSAGE'

export const SET_RESPONSIVE = 'SET_RESPONSIVE'
