import { InternalCustomFields } from '@/common/helpers/enums/fields.enum'

export const isSystemField = (type: number) => {
  switch (type) {
    case InternalCustomFields.Text:
    case InternalCustomFields.MultiText:
    case InternalCustomFields.List:
    case InternalCustomFields.SearchableList:
    case InternalCustomFields.Select:
    case InternalCustomFields.Numbers:
    case InternalCustomFields.Decimal:
    case InternalCustomFields.Date:
    case InternalCustomFields.Subcategory:
      return false
    case InternalCustomFields.Requester:
    case InternalCustomFields.Forwarding:
    case InternalCustomFields.PorductService:
    case InternalCustomFields.Type:
    case InternalCustomFields.Priority:
    case InternalCustomFields.SubjectCategory:
    case InternalCustomFields.Responsible:
    case InternalCustomFields.Tags:
    case InternalCustomFields.ReplyMail:
    case InternalCustomFields.Organization:
      return true
  }
}
