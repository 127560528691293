<template>
  <octa-title tag="h6" class="pl-1 mb-2">
    <slot />
  </octa-title>
</template>

<script>
  import { OctaTitle } from '@octadesk-tech/web-components'

  export default {
    components: {
      OctaTitle
    }
  }
</script>
