<template>
  <octa-list-item
    v-if="!expanse"
    v-show="show"
    v-bind="$attrs"
    v-on="$listeners"
    role="listitem"
    :class="[
      '!px-1.5',
      {
        [this.$style['submenu-link']]: true,
        [this.$style['submenu-link--active']]: this.active
      }
    ]"
  >
    <octa-list-item-content :class="$style['submenu-link-content']">
      <slot name="content" />
    </octa-list-item-content>

    <octa-list-item-action
      v-show="$slots.action"
      :class="$style['submenu-link-action']"
    >
      <slot name="action" />
    </octa-list-item-action>
  </octa-list-item>

  <span role="listitem" v-else>
    <octa-accordion-item
      :class="$style['submenu-link']"
      :toggle-icon="toggleIcon"
    >
      <slot />
    </octa-accordion-item>

    <octa-accordion-content transition="slide-y">
      <slot name="content" />
    </octa-accordion-content>
  </span>
</template>

<script>
  import {
    OctaAccordionItem,
    OctaAccordionContent,
    OctaListItem,
    OctaListItemContent,
    OctaListItemAction
  } from '@octadesk-tech/web-components'

  export default {
    components: {
      OctaListItem,
      OctaListItemContent,
      OctaListItemAction,
      OctaAccordionItem,
      OctaAccordionContent
    },
    props: {
      to: {
        type: [String, Object],
        default: null
      },
      expanse: Boolean,
      toggleIcon: {
        type: String,
        default: undefined
      },
      active: Boolean,
      show: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="scss" module>
  .submenu-link {
    @apply font-medium text-xs font-poppins cursor-pointer;
    @apply py-0 rounded-lg min-h-8 mb-2;
    color: var(--octa-navigation-item-color, theme('colors.gray.600'));

    &:active,
    &--active {
      @apply font-bold;
      background-color: var(
        --octa-navigation-item-active-bg-color,
        theme('colors.gray.800')
      );
      color: var(--octa-navigation-item-active-color, theme('colors.white'));
    }

    &:hover {
      background-color: var(
        --octa-navigation-item-hover-bg-color,
        theme('colors.gray.800')
      );
      color: var(--octa-navigation-item-hover-color, theme('colors.gray.200'));
    }

    &-content {
      @apply overflow-hidden;
      color: inherit;
    }

    &-action {
      @apply flex justify-center;
      color: inherit;
    }
  }
</style>
