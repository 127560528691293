/* eslint-disable sonarjs/no-identical-functions */
import store from '@/store'

const eventsTypes = {
  closed: {
    mapper(e, chat) {
      if (chat.closed && chat.closed.time && !(chat.leave && chat.leave.time)) {
        return {
          type: 'ChatConversationClosed',
          property: 'closed',
          time: chat.closed.time,
          value: e.value
        }
      }
    },
    equals(current, other) {
      return (
        current.type === other.type &&
        current.value.user &&
        other.value.user &&
        current.value.user.name === other.value.user.name
      )
    }
  },
  leave: {
    mapper(e) {
      return {
        type: 'ChatConversationLeave',
        property: 'leave',
        time: e.value.time,
        value: e.value
      }
    },
    equals(current, other) {
      return (
        current.type === other.type &&
        current.value.user &&
        other.value.user &&
        current.value.user.name === other.value.user.name
      )
    }
  },
  agent: {
    mapper(e) {
      return {
        type: 'ChatConversationAgent',
        property: 'agent',
        time: e.time,
        value: e.value,
        ownerAction: e.user
      }
    },
    equals(current, other) {
      return (
        current.type === other.type &&
        ((!current.value && !other.value) ||
          (current.value &&
            other.value &&
            current.value.name === other.value.name))
      )
    }
  },
  group: {
    mapper(e) {
      return {
        type: 'ChatConversationGroup',
        property: 'agent',
        time: e.time,
        value: e.value,
        ownerAction: e.user
      }
    },
    equals(current, other) {
      return (
        current.type === other.type &&
        ((!current.value && !other.value) ||
          (current.value &&
            other.value &&
            current.value.name === other.value.name))
      )
    }
  },
  ticket: {
    mapper(e) {
      return {
        type: 'ChatConversationTicket',
        property: 'ticket',
        time: e.time,
        value: e.value
      }
    },
    equals(current, other) {
      return current.type === other.type && current.value === other.value
    }
  },
  domainFrom: {
    mapper(e) {
      return {
        type: 'ChatConversationDomainFrom',
        property: 'domainFrom',
        time: e.time,
        value: e.value
      }
    },
    equals(current, other) {
      return current.type === other.type && current.value === other.value
    }
  },
  customField: {
    mapper(e) {
      return {
        type: 'ChatConversationCustomField',
        property: 'customField',
        time: e.time,
        value: e.value
      }
    },
    equals(current, other) {
      return current.type === other.type && current.value === other.value
    }
  },
  'createdBy.name': {
    mapper(e) {
      return {
        type: 'ChatConversationCreatedByName',
        property: 'createdBy.name',
        time: e.time,
        value: e.value
      }
    },
    equals(current, other) {
      return (
        current.type === other.type && current.value.new === other.value.new
      )
    }
  },
  'createdBy.email': {
    mapper(e) {
      return {
        type: 'ChatConversationCreatedByEmail',
        property: 'createdBy.email',
        time: e.time,
        value: e.value
      }
    },
    equals(current, other) {
      return (
        current.type === other.type && current.value.new === other.value.new
      )
    }
  },
  reminder: {
    mapper(e) {
      return {
        type: 'ChatConversationReminder',
        property: 'reminder',
        time: e.time,
        value: e.value
      }
    },
    equals(current, other) {
      return current.type === other.type && current.value === other.value
    },
    filter(event) {
      return event?.value?.reminder?.ownerId === store.getters['chat/agentId']
    }
  },
  askMe: {
    mapper(e) {
      return {
        type: 'ChatConversationAskMe',
        property: 'askMe',
        time: e.time,
        value: e.value
      }
    },
    equals(current, other) {
      return current.type === other.type && current.value === other.value
    }
  }
}

const createMessageEvent = (message, index, arr) => {
  return {
    type: 'ChatConversationMessage',
    time: message.time,
    uniqueKey: message.key,
    value: {
      message: message,
      previousMessage: arr[index - 1],
      nextMessage: arr[index + 1],
      key: message.key,
      index
    }
  }
}

const eventAlreadyAdded = (events, event) => {
  if (events && events.length && eventsTypes[event.property]) {
    const lastEvent = events[events.length - 1]
    return eventsTypes[event.property]?.equals(event, lastEvent)
  }
}

const parseMessages = (chat, messages) => {
  let renderedMessages = chat.messages

  let sessionMessages = messages.filter(m => m.chatKey === chat.key)

  if (sessionMessages.length) {
    renderedMessages = chat.messages.filter(renderedMessage => {
      const matched = sessionMessages.find(
        sessionMsg => sessionMsg.key === renderedMessage.key
      )

      return !matched || matched.time === renderedMessage.time
    })

    sessionMessages = sessionMessages.filter(
      sessionMsg =>
        !renderedMessages.some(
          renderedMessage => renderedMessage.key === sessionMsg.key
        )
    )
  }

  const messageEvents = renderedMessages?.concat(sessionMessages) ?? []

  return messageEvents?.map(createMessageEvent) ?? []
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export default (chat, messages) => {
  let events = []

  if (chat) {
    events = parseMessages(chat, messages)

    if (chat.events) {
      const lastMessagePage = chat?.messages?.[0]

      chat.events.forEach(e => {
        if (eventsTypes[e.property]) {
          const eventType = eventsTypes[e.property]

          const event = eventType.mapper(e, chat)

          const filter = !eventType.filter || eventType.filter(event)

          if (
            event &&
            !eventAlreadyAdded(events, event) &&
            filter &&
            (!lastMessagePage || event.time >= lastMessagePage.time)
          ) {
            events.push(event)
          }
        }
      })
    }
  }

  events.sort((c, n) => {
    const cTime = new Date(c.time)

    const nTime = new Date(n.time)

    if (cTime > nTime) {
      return 1
    } else if (cTime < nTime) {
      return -1
    } else {
      return 0
    }
  })

  return events
}
